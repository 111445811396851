exports.components = {
  "component---src-components-blog-archive-index-js": () => import("./../../../src/components/BlogArchive/index.js" /* webpackChunkName: "component---src-components-blog-archive-index-js" */),
  "component---src-components-blog-article-index-js": () => import("./../../../src/components/BlogArticle/index.js" /* webpackChunkName: "component---src-components-blog-article-index-js" */),
  "component---src-components-lijster-index-js": () => import("./../../../src/components/Lijster/index.js" /* webpackChunkName: "component---src-components-lijster-index-js" */),
  "component---src-components-page-index-js": () => import("./../../../src/components/Page/index.js" /* webpackChunkName: "component---src-components-page-index-js" */),
  "component---src-components-page-subpage-index-js": () => import("./../../../src/components/Page/Subpage/index.js" /* webpackChunkName: "component---src-components-page-subpage-index-js" */),
  "component---src-components-page-subpage-mensen-index-js": () => import("./../../../src/components/Page/SubpageMensen/index.js" /* webpackChunkName: "component---src-components-page-subpage-mensen-index-js" */),
  "component---src-components-person-index-js": () => import("./../../../src/components/Person/index.js" /* webpackChunkName: "component---src-components-person-index-js" */),
  "component---src-components-portefeuille-index-js": () => import("./../../../src/components/Portefeuille/index.js" /* webpackChunkName: "component---src-components-portefeuille-index-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-canivote-index-js": () => import("./../../../src/pages/canivote/index.js" /* webpackChunkName: "component---src-pages-canivote-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meedoen-index-js": () => import("./../../../src/pages/meedoen/index.js" /* webpackChunkName: "component---src-pages-meedoen-index-js" */),
  "component---src-pages-mensen-index-js": () => import("./../../../src/pages/mensen/index.js" /* webpackChunkName: "component---src-pages-mensen-index-js" */),
  "component---src-pages-mensen-lijst-index-js": () => import("./../../../src/pages/mensen/lijst/index.js" /* webpackChunkName: "component---src-pages-mensen-lijst-index-js" */),
  "component---src-pages-mensen-oud-fractie-index-js": () => import("./../../../src/pages/mensen/oud-fractie/index.js" /* webpackChunkName: "component---src-pages-mensen-oud-fractie-index-js" */),
  "component---src-pages-podcast-index-js": () => import("./../../../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-pages-portefeuilles-index-js": () => import("./../../../src/pages/portefeuilles/index.js" /* webpackChunkName: "component---src-pages-portefeuilles-index-js" */),
  "component---src-pages-speerpunten-moties-index-js": () => import("./../../../src/pages/speerpunten/moties/index.js" /* webpackChunkName: "component---src-pages-speerpunten-moties-index-js" */),
  "component---src-pages-speerpunten-successen-index-js": () => import("./../../../src/pages/speerpunten/successen/index.js" /* webpackChunkName: "component---src-pages-speerpunten-successen-index-js" */)
}

